<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
      <Btn
        class="ml-3"
        color="warning"
        icon="toy-brick-plus-outline"
        :disabled="!add"
        @click="copyschedule()"
        >スケジュール参照</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selecthospital"
          :items="hospitals"
          label="病院"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-hospital-building"
          return-object
        ></v-autocomplete>
        <v-text-field
          v-model.number="packageNo"
          label="パッケージ番号"
          type="number"
          outlined
          prepend-icon="mdi-numeric"
          v-show="false"
        ></v-text-field>
        <v-row class="margin-row">
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="daycount"
              label="日数"
              type="number"
              suffix="日"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <label>開始時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="startTime"
              placeholder="開始時間"
              manual-input
              auto-scroll
              input-width="95%"
              :disabled="!edit"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <v-col class="dialog-padding">
            <label>終了時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="endTime"
              placeholder="終了時間"
              manual-input
              auto-scroll
              input-width="95%"
              :disabled="!edit"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <!--
          <v-col class="dialog-padding">
            <TimePicker
              v-model="startTime"
              label="開始時間"
              prepend-icon="mdi-clock-start"
              clearable
              outlined
            />
          </v-col>
          <v-col class="dialog-padding">
            <TimePicker
              v-model="endTime"
              label="終了時間"
              prepend-icon="mdi-clock-end"
              clearable
              outlined
            />
          </v-col>
          -->
        </v-row>
        <v-row class="margin-row">
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="ninsuu"
              label="人数"
              type="number"
              suffix="人"
              outlined
              prepend-icon="mdi-human-queue"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-autocomplete
              v-model="selectcycle"
              :items="cycles"
              label="作業サイクル"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-sync-circle"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="monthcount"
              label="月回数"
              type="number"
              suffix="回"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
          </v-col>
        </v-row>
        <DatePicker
          v-model="startdate"
          label="開始年月"
          prepend-icon="mdi-calendar"
          outlined
          type="month"
          :rules="[Rules.Required]"
        />
        <v-autocomplete
          v-model="selectFilterCompany"
          :items="filterCompany"
          label="協力会社"
          prepend-icon="mdi-account-cog"
          outlined
          item-text="name"
          item-value="code"
          return-object
          clearable
        ></v-autocomplete>
        <v-text-field
          v-model="weekday"
          label="曜日備考"
          outlined
          prepend-icon="mdi-calendar-week"
          maxlength="45"
        ></v-text-field>
        <v-row style="height: 60px;">
          <v-col cols="2">
            <v-text-field
              value="指定曜日"
              prepend-icon="mdi-calendar-week"
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox v-model="monday" label="月"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="tuesday" label="火"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="wednesday" label="水"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="thursday" label="木"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="friday" label="金"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="saturday"
              label="土"
              color="secondary"
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="sunday" label="日" color="error"> </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="nholiday" label="祝" color="success">
            </v-checkbox>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="height: 90px;">
          <v-col cols="2">
            <v-text-field
              value="指定週"
              prepend-icon="mdi-calendar-week"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week1" label="1週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week2" label="2週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week3" label="3週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week4" label="4週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week5" label="5週目"></v-checkbox>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
        <v-radio-group
          v-model="selectNationalHoliday"
          row
          label="祝日設定"
          prepend-icon="mdi-toggle-swtich"
        >
          <v-radio
            label="指定曜日祝日対象外"
            :value="SelectsNationalHoliday.No"
          ></v-radio>
          <v-radio
            label="指定曜日祝日対象"
            :value="SelectsNationalHoliday.YesWd"
          ></v-radio>
          <!-- <v-radio
            label="祝日指定"
            :value="selectNationalHoliday.YesHd"
          ></v-radio> -->
        </v-radio-group>
        <!-- <v-row>
          <v-text-field
            v-model="nhoff"
            prepend-icon="mdi-information"
            dense
            readonly
          ></v-text-field>
        </v-row>-->
        <v-row>
          <p
            prepend-icon="mdi-information"
            style="color:red"
            class="text-h7 spacing-playground pa-1"
          >
            <strong>{{ nhoff }}</strong>
          </p>
        </v-row>
        <!-- <v-row>
          <v-text-field
            v-model="nhon"
            prepend-icon="mdi-information"
            dense
            readonly
          ></v-text-field>
        </v-row> -->
        <v-row v-for="(item, i) in meisai" :key="i">
          <v-text-field
            :value="item.display"
            class="mr-5"
            :label="'明細内容 ' + (i + 1)"
            outlined
            dense
            readonly
            prepend-icon="mdi-close"
            @click:prepend="clickDelRow(i)"
            required
            :rules="[Rules.Required]"
          />
          <v-btn
            color="success"
            @click="showRowDialog(i)"
            :disabled="!edit"
            v-if="edit"
          >
            編集</v-btn
          >
        </v-row>
        <v-btn rounded color="primary" small @click="addrow" :disabled="!edit">
          <v-icon>mdi-plus</v-icon> 明細を追加</v-btn
        >

        <v-radio-group
          v-model="selectDelete"
          row
          label="削除フラグ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import Package from "@/models/Package";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";
import PackageMeisaiDialog from "../dialogs/PackageMeisaiDialog";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import SpotreferecnceDialog from "../dialogs/SpotreferecnceDialog.vue";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const SelectsDelete = {
  Delete: 0,
  Default: 1
};
const SelectsNationalHoliday = {
  No: 0,
  YesWd: 1,
  YesHd: 2
};

export default {
  name: "PackageDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  components: {
    "vue-timepicker": VueTimepicker
  },
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      hospitals: [],
      selecthospital: { code: "", name: "" },
      packageNo: null,
      startdate: null,
      selectcycle: { code: "", name: "" },
      cycles: [],
      monthcount: null,
      daycount: null,
      weekday: "",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      nholiday: false,
      week1: false,
      week2: false,
      week3: false,
      week4: false,
      week5: false,
      ninsuu: null,
      startTime: null,
      endTime: null,
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      selectNationalHoliday: SelectsNationalHoliday.YesWd,
      SelectsNationalHoliday,
      filterCompany: [],
      selectFilterCompany: [],
      meisai: [{ packageMeisaiNo: "1", display: "" }],
      nhoff: ""
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.add);
      this.$loading();

      try {
        const hpcd = this.selecthospital.code;
        const hojinCd = hpcd.substr(0, 2);
        const hospitalCd = hpcd.substr(2, 3);

        const cycle = Number(this.selectcycle.code);

        //共通の入力チェック
        if (hpcd == "") {
          this.$error("病院が選択されていません。");
          return;
        }

        if (
          this.selectcycle.code == undefined ||
          this.selectcycle.code == null ||
          this.selectcycle.code == ""
        ) {
          this.$error("作業サイクルが選択されていません。");
          return;
        }

        //随時のパターンが増えたので入力チェックをここで実行
        if (cycle > 0) {
          //日数、人数、月数は、随時以外の場合に必須
          if (this.daycount == undefined || this.daycount == null) {
            this.$error("日数を入力してください。");
            return;
          }
          if (this.ninsuu == undefined || this.ninsuu == null) {
            this.$error("人数を入力してください。");
            return;
          }
          if (this.monthcount == undefined || this.monthcount == null) {
            this.$error("月回数を入力してください。");
            return;
          }
        }

        if (this.meisai.length == 0) {
          this.$error("明細を１件以上、入力してください。");
          return;
        }

        const meisaiList = [];
        this.meisai.forEach(e => {
          meisaiList.push({
            packageMeisaiNo: e.packageMeisaiNo,
            workPattern: e.workPattern,
            floor: e.floor,
            area: e.area,
            workarea: e.workarea,
            workareasuu: e.workareasuu,
            workareasuukbn: e.workareasuukbn,
            cycle: e.cycle == undefined ? "" : e.cycle,
            index: e.index
          });
        });

        const ymd = this.startdate.split("-");
        console.log("startdate", ymd);
        console.log("starttime", this.startTime);
        console.log("endtime", this.endTime);
        console.log("selectFilterCompany", this.selectFilterCompany);

        const start = [""];
        const end = [""];

        if (
          this.startTime != undefined &&
          this.startTime.HH != null &&
          this.startTime.HH != undefined
        ) {
          start[0] = this.startTime.HH + ":" + this.startTime.mm;
        } else if (this.startTime != null && this.startTime != undefined) {
          start[0] = this.startTime;
        } else {
          start[0] = null;
        }
        if (
          this.endTime != undefined &&
          this.endTime.HH != null &&
          this.endTime.HH != undefined
        ) {
          end[0] = this.endTime.HH + ":" + this.endTime.mm;
        } else if (this.endTime != null && this.endTime != undefined) {
          end[0] = this.endTime;
        } else {
          end[0] = null;
        }

        //console.log(this.selectFilterCompany.code);

        const param = new Package(
          hojinCd,
          hospitalCd,
          this.packageNo,
          ymd[0] + "-" + ymd[1] + "-01",
          cycle,
          this.monthcount,
          this.daycount,
          this.weekday,
          this.monday == true ? "1" : "0",
          this.tuesday == true ? "1" : "0",
          this.wednesday == true ? "1" : "0",
          this.thursday == true ? "1" : "0",
          this.friday == true ? "1" : "0",
          this.saturday == true ? "1" : "0",
          this.sunday == true ? "1" : "0",
          this.nholiday == true ? "1" : "0",
          this.week1 == true ? "1" : "0",
          this.week2 == true ? "1" : "0",
          this.week3 == true ? "1" : "0",
          this.week4 == true ? "1" : "0",
          this.week5 == true ? "1" : "0",
          this.selectNationalHoliday,
          this.ninsuu,
          start[0],
          end[0],
          this.selectFilterCompany == null ? "" : this.selectFilterCompany.code,
          this.selectDelete,
          meisaiList
        );

        console.log("submit_param", param);
        console.log("add", this.add);

        if (this.add) {
          const data = await this.$post(this.Paths.package, param);
          //this.recordId = data.recordId;
          // 追加モードのままだとパッケージ番号の部分がnullで処理されるので一度一覧に戻す
          const path = "/list/package";
          this.$router.push({
            path: path,
            query: { filter: this.params.filter }
          });
        } else {
          await this.$put(this.Paths.package, param);
        }

        this.$info("更新しました。", "パッケージ情報");
        this.edit = false;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async copyschedule() {
      //スポットの場合に過去に登録されたスポットの明細を取得します。
      console.log("spot", "spotsearch");
      console.log("Hp", this.selecthospital.code);
      const packs = [];
      packs.push({
        schedule: "",
        affiliations: this.selecthospital.code
      });
      console.log(packs);

      const props = { args: packs };
      console.log(props);

      const result = await this.$showDialog(SpotreferecnceDialog, props);

      if (result.args == undefined) {
        return;
      }

      const args = result.args;

      this.meisai = [];
      const ms = [];
      for (let i = 0; i < args.length; i++) {
        if (i == 0) {
          //物件情報を設定
          const filterd = this.hospitals.filter(
            e => e.code === String(args[i].hojinCd + args[i].hospitalCd)
          );
          if (filterd.length > 0) {
            this.selecthospital = filterd[0];
          }

          //人数を設定
          let count = 0;
          let tanto = args[i].packagemeisais[7].code.split(",");
          for (let j = 0; j < tanto.length; j++) {
            if (tanto[j] != "") {
              count = count + 1;
            }
          }
          tanto = args[i].packagemeisais[8].code.split(",");
          for (let j = 0; j < tanto.length; j++) {
            if (tanto[j] != "") {
              count = count + 1;
            }
          }
          this.ninsuu = count;

          //開始、終了時刻
          if (args[i].packagemeisais[5].name != "") {
            this.startTime = args[i].packagemeisais[5].name;
          }
          if (args[i].packagemeisais[6].name != "") {
            this.endTime = args[i].packagemeisais[6].name;
          }
        }

        //明細情報を設定する
        const setms = {
          packageMeisaiNo: i + 1,
          workPattern: args[i].packagemeisais[3].name,
          floor: args[i].packagemeisais[9].name,
          area: args[i].packagemeisais[10].name,
          workarea: args[i].packagemeisais[11].name,
          workareasuu: args[i].packagemeisais[11].suu,
          workareasuukbn: args[i].packagemeisais[11].kbn,
          cycle: "",
          index: i + 1,
          display:
            args[i].packagemeisais[3].name +
            "-" +
            args[i].packagemeisais[9].name +
            "-" +
            args[i].packagemeisais[10].name
        };

        ms.push(setms);
      }

      this.meisai = ms;
    },
    back() {
      const path = "/list/package";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    addrow() {
      const index = this.meisai.length + 1;
      this.meisai.push({ packageMeisaiNo: index, display: "" });
    },
    async showRowDialog(i) {
      console.log(`showDialog(${i})`);
      const props = { args: this.meisai[i] }; //{ users: users };
      console.log(props);
      const result = await this.$showDialog(PackageMeisaiDialog, props);
      if (result) {
        console.log(result);

        Object.keys(result).forEach(key => (this.meisai[i][key] = result[key]));
      }
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    },
    async clickDelRow(i) {
      if (this.meisai.workPattern) {
        const msg = `明細 ${i + 1} を削除します。<br>よろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
      }
      this.meisai.splice(i, 1);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.CYCLE);
      this.cycles = filter.filter(e => e.code !== NotSetCode.CYCLE);

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HLP);
      this.filterCompany = filter.filter(e => e.code !== NotSetCode.HLP);
    },
    getTextIetems() {
      console.log("getTextItems", this.codeMasterItems);
      let textlst = [];
      textlst = this.codeMasterItems.filter(e => e.refcode === CodeGroup.TEXT);
      const text = textlst.filter(e => e.code === "0");
      console.log("text1", text);
      this.nhoff = text[0].option2;
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.hospitals = await this.getHospital();
      await this.getCodeMasterItems();
      this.getPullDownItems();

      this.getTextIetems();
      //this.nhoff = "「祝日なし」を選択した場合、指定曜日と指定週の条件に一致する日であっても祝日の場合は仮予定日として利用されません";
      //this.nhon = "「祝日あり」を選択した場合で指定週が選ばれていなければその月最初の祝日又は指定週の条件に当てはまる日が仮予定日となります";

      if (!this.add) {
        const filterd = this.hospitals.filter(
          e => e.code === String(this.args.hojinHospitalCd)
        );

        //const test = this.args;
        //const filterd = this.hospitals

        if (filterd.length > 0) {
          this.selecthospital = filterd[0];
        }

        this.packageNo = this.args.packageNo;
        const dt = new Date(this.args.startdate);
        this.startdate =
          dt.getFullYear() +
          "-" +
          ("00" + Number(dt.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + Number(dt.getDate())).slice(-2);

        const Cycle = this.args.cycle == null ? "0" : String(this.args.cycle);
        const filterCycle = this.cycles.filter(e => e.code === Cycle);
        if (filterCycle.length > 0) {
          this.selectcycle.code = filterCycle[0].code;
          this.selectcycle.name = filterCycle[0].name;
        }
        //this.cycle = this.args.cycle;
        this.monthcount = this.args.monthcount;
        this.daycount = this.args.daycount;
        this.weekday = this.args.weekday;
        this.ninsuu = this.args.ninsuu;
        if (this.args.startTime != null) {
          this.startTime = this.args.startTime.substr(0, 5);
        }
        if (this.args.endTime != null) {
          this.endTime = this.args.endTime.substr(0, 5);
        }

        if (this.args.company !== null) {
          const CompanyCd = this.args.company == null ? "0" : this.args.company;
          const filtercompany = this.filterCompany.filter(
            e => e.code === CompanyCd
          );
          if (filtercompany.length > 0) {
            this.selectFilterCompany.code = filtercompany[0].code;
            this.selectFilterCompany.name = filtercompany[0].name;
          }
        }

        //曜日、週の値の設定
        if (this.args.monday == "1") {
          this.monday = true;
        }
        if (this.args.tuesday == "1") {
          this.tuesday = true;
        }
        if (this.args.wednesday == "1") {
          this.wednesday = true;
        }
        if (this.args.thursday == "1") {
          this.thursday = true;
        }
        if (this.args.friday == "1") {
          this.friday = true;
        }
        if (this.args.saturday == "1") {
          this.saturday = true;
        }
        if (this.args.sunday == "1") {
          this.sunday = true;
        }
        if (this.args.nholiday == "1") {
          this.nholiday = true;
        }
        if (this.args.week1 == "1") {
          this.week1 = true;
        }
        if (this.args.week2 == "1") {
          this.week2 = true;
        }
        if (this.args.week3 == "1") {
          this.week3 = true;
        }
        if (this.args.week4 == "1") {
          this.week4 = true;
        }
        if (this.args.week5 == "1") {
          this.week5 = true;
        }

        if (this.args.nationalHoliday === "0") {
          this.selectNationalHoliday = this.SelectsNationalHoliday.No;
        } else if (this.args.nationalHoliday === "2") {
          this.selectNationalHoliday = this.SelectsNationalHoliday.YesHd;
        }

        const isvalid = this.args.isValid;
        if (this.args.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }

        const m = this.args.meisai;
        const ms = [];
        for (let i = 0; i < m.length; i++) {
          let disp = m[i].workPattern;
          if (m[i].floor !== "") {
            disp = disp + "-" + m[i].floor;
          }
          if (m[i].area !== "") {
            disp = disp + "-" + m[i].area;
          }
          const setms = {
            packageMeisaiNo: m[i].packageMeisaiNo,
            workPattern: m[i].workPattern,
            floor: m[i].floor,
            area: m[i].area,
            workarea: m[i].workarea,
            workareasuu: m[i].workareasuu,
            workareasuukbn: m[i].workareasuukbn,
            cycle: m[i].cycle,
            index: m[i].index,
            display: disp
          };

          ms.push(setms);
        }
        this.meisai = ms;
        //this.meisai = this.args.meisai;
      } else {
        const dt = new Date();
        this.startdate =
          dt.getFullYear() +
          "-" +
          ("00" + Number(dt.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + Number(dt.getDate())).slice(-2);
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style scoped>
label {
  font-size: 1em;
}
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
